import React from "react";
import { Glyphicon } from "react-bootstrap";
import "./Spinner.css";

export default ({
                  isLoading,
                  className = "",
                  ...props
                }) =>
  <div
    className={`Spinner ${className}`}
    {...props}
  >
    {isLoading && <Glyphicon glyph="refresh" className="spinning" />}
  </div>;
