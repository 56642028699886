//
import React from 'react';

const authContext = React.createContext({
  authenticated: false,
  login: () => {}
  //custRec: null
});

export default authContext;
