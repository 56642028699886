import React from "react";
import { Route, Switch } from "react-router-dom";
import Home from "./containers/Home";
import NotFound from "./containers/NotFound";
import Google from "./containers/Google";
import Yelp from "./containers/Yelp";
import Signup from "./containers/Signup";
import Login from "./containers/Login";
import Logout from "./containers/Logout";
import Welcome from "./containers/Welcome";
import Settings from "./containers/Settings";
import ChartTests from "./containers/ChartTests";

export default () =>
  <Switch>
    <Route path="/" exact component={Home} />
    <Route path="/google" exact component={Google} />
    <Route path="/yelp" exact component={Yelp} />
    <Route path="/signup" exact component={Signup} />
    <Route path="/login" exact component={Login} />
    <Route path="/logout" exact component={Logout} />
    <Route path="/welcome" exact component={Welcome} />
    <Route path="/settings" exact component={Settings} />
    <Route path="/chart" exact component={ChartTests} />
    { /* Finally, catch all unmatched routes */ }
    <Route component={NotFound} />
  </Switch>;
