import React from "react";
import { Table } from "react-bootstrap";
import './YelpSearchResult.css';

export default (props) => {

  let engine = props.engine;
  let serpItems = props.serpItems;
  let customer_yelp_url = props.customer_yelp_url;
  let searchResults = [];

  // order by search term / location, then by time
  serpItems = serpItems.sort(compareValues('search_link', 'asc'));
  serpItems = serpItems.sort(compareValues('search_end_time', 'desc'));

  // remove search url encoding for a cleaner search name display
  let cleanSearchLink = (link) => {
    try {
      //return link.toString().replace(/^.*cflt=/, '')
      return link.toString().replace(/^.*find_desc=/, '')
        .replace(/&find_loc=/g, ' ');

    } catch (err) {
      //console.error(err, err.stack);
    }
  };

  const formatDate = (timestamp) => {
    let formatted = new Date(parseInt(timestamp));
    //formatted = formatted.toDateString() + ' ' + formatted.toTimeString();
    formatted = formatted.toDateString();
    return formatted;
  };

  // sort object values
  function compareValues(key, order='asc') {
    return function(a, b) {
      if(!a.hasOwnProperty(key) ||
        !b.hasOwnProperty(key)) {
        return 0;
      }

      const varA = (typeof a[key] === 'string') ?
        a[key].toUpperCase() : a[key];
      const varB = (typeof b[key] === 'string') ?
        b[key].toUpperCase() : b[key];

      let comparison = 0;
      if (varA > varB) {
        comparison = 1;
      } else if (varA < varB) {
        comparison = -1;
      }
      return (
        (order === 'desc') ?
          (comparison * -1) : comparison
      );
    };
  }

  // highlight customer url when found in results
  function findURL(resultItem) {
    try {
      if (resultItem) {
        if (resultItem.toLowerCase().includes(`${customer_yelp_url}`)) {
          return true;
        } else {
          return false;
        }
      } else {
        // item is null or undefined, do nothing for now
      }
    } catch (err) {
      console.error(err, err.stack);
    }
  }

  // highlight yelp customer ads when found in results
  function findAds(resultItem) {
    try {
      if (resultItem) {
        if (resultItem.toLowerCase().includes('adredir')) {
          return true;
        } else {
          return false;
        }
      } else {
        // item is null or undefined, do nothing for now
      }
    } catch (err) {
      console.error(err, err.stack);
    }
  }


  // create our page displays
  let displayPage = (page) => {
    try {
      if (page) {
        // convert object to array
        let pageArray = Object.entries(page);
        let pResults = [];
        //let chartData = [];
        pageArray.sort().forEach((pos, index) => {

          // deconstruct our pre-existing anchor elements to create them AGAIN below to make react happy when injecting
          let ourURL = pos[1].toString()
            .replace(/^<a href="/g, '')
            .replace(/".*$/g, '');

          let bizName = pos[1].toString()
            .replace(/^<a.*">/g, '')
            .replace(/<\/a>$/g, '');

          // highlight customer url
          if(findURL(ourURL)) {
            pResults.push(<table key={index}><tbody><tr><td key={index} className="y-table-cell-url">{pos[0]}</td><td key={index + 1} className="y-table-cell-url"><span className="y-our-url"><a href={ourURL} rel="noopener noreferrer" target="_blank">{bizName}</a></span></td></tr></tbody></table>);
          } else {
            // indicate if other urls are ads
            if(findAds(ourURL)) {
              pResults.push(<table key={index}><tbody><tr><td key={index} className="y-table-cell-url">{pos[0]}</td><td key={index + 1} className="y-table-cell-url"><span className="y-comp-url"><span className="yelp-ad">Ad </span></span><a href={ourURL} rel="noopener noreferrer" target="_blank">{bizName}</a></td></tr></tbody></table>);
            } else {
              pResults.push(<table key={index}><tbody><tr><td key={index} className="y-table-cell-url">{pos[0]}</td><td key={index + 1} className="y-table-cell-url"><span className="y-comp-url"><a href={ourURL} rel="noopener noreferrer" target="_blank">{bizName}</a></span></td></tr></tbody></table>);
            }
          }

        });
        return pResults;
      } else {
        return <p>There were no results for this page</p>;
      }
    } catch (err) {
      console.error(err, err.stack);
    }
  };

  serpItems.forEach((item, index) => {
    searchResults.push(
      <Table key={index} striped bordered condensed hover responsive>

        <thead>
        <tr>
          <th className="y-table-header" colSpan="6"><span className="y-table-date"> {`${formatDate(item.search_end_time)}`}</span> {`${engine} Search: `}<a href={item.search_link} rel="noopener noreferrer" title="Clicking this will search in real time" target="blank">"{cleanSearchLink(item.search_link)}"</a></th>
        </tr>
        </thead>

        <tbody>

        <tr>
          <th className="y-table-page-header">Page One</th>
          <th className="y-table-page-header">Page Two</th>
          <th className="y-table-page-header">Page Three</th>
        </tr>

        <tr>
          <td>{displayPage(item.search_results.page_01)}</td>
          <td>{displayPage(item.search_results.page_02)}</td>
          <td>{displayPage(item.search_results.page_03)}</td>
        </tr>

        </tbody>

      </Table>
    );
  });

  return searchResults;
}

