export default {
  apiGateway: {
    REGION: "us-west-2",
    URL: "https://pg0acxqq87.execute-api.us-west-2.amazonaws.com/dev"
  },
  cognito: {
    REGION: "us-west-2",
    USER_POOL_ID: "us-west-2_ftHBCmLQq",
    APP_CLIENT_ID: "27dddcgmvig4jrvha3sllaqs73",
    IDENTITY_POOL_ID: "us-west-2:f4ade730-5199-44ee-aaf6-ab163d804a17"
  }
};
