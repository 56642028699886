import React, { useState, useContext } from "react";
import { HelpBlock, FormGroup, FormControl, ControlLabel } from "react-bootstrap";
import LoaderButton from "../components/LoaderButton";
import "./Signup.css";
import { Auth, API } from "aws-amplify";
import AuthContext from '../auth-context';

const Signup = (props) => {

  const auth = useContext(AuthContext);

  const [isLoading, setIsLoading] = useState(false);
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [confirmationCode, setConfirmationCode] = useState("");
  const [newUser, setNewUser] = useState(null);

  const validateForm = () => {
    return (
      email.length > 0 &&
      password.length > 0 &&
      password === confirmPassword
    );
  };

  const validateConfirmationForm = () => {
    return confirmationCode.length > 0;
  };

  const handleSubmit = async event => {
    event.preventDefault();

    setIsLoading(true);

    // TODO: add additional custom atts
    try {
        const newUserFresh = await Auth.signUp({
        'username': email,
        'password': password,
        /*
        // custom atts must be defined in Cognito before sending
        'attributes': {
          'custom:testatt': 'myNewAtt'
        }
        */
      });

      setNewUser(newUserFresh);

      //console.log(newUserFresh);

    } catch (e) {
      alert(e.message);
    }

    setIsLoading(false);
  };

  const handleConfirmationSubmit = async event => {
    event.preventDefault();

    setIsLoading(true);

    try {
      await Auth.confirmSignUp(email, confirmationCode);
      await Auth.signIn(email, password);
      auth.login();

      // grab our new user info for insertion into db
      const user = await Auth.currentUserInfo();
      const { id, username, attributes } = user;

      // TODO: include any custom attributes desired
      await API.post('dev-serp-api-v5', '/signup', {
        body: {
          identity_id: id,
          search_id: 'customer_record',
          user_id: username,
          email: attributes.email
        }
      });

      props.history.push("/welcome");

    } catch (e) {
      alert(e.message);
      setIsLoading(false);
    }
  };

  const renderConfirmationForm = () => {
    return (
      <form onSubmit={handleConfirmationSubmit}>
        <FormGroup controlId="confirmationCode" bsSize="large">
          <ControlLabel>Confirmation Code</ControlLabel>
          <FormControl
            autoFocus
            type="tel"
            value={confirmationCode}
            onChange={e => setConfirmationCode(e.target.value)}
          />
          <HelpBlock>Please check your email for the code.</HelpBlock>
        </FormGroup>
        <LoaderButton
          block
          bsSize="large"
          disabled={!validateConfirmationForm()}
          type="submit"
          isLoading={isLoading}
          text="Verify"
          loadingText="Verifying…"
        />
      </form>
    );
  };

  const renderForm = () => {
    return (
      <form onSubmit={handleSubmit}>
        <FormGroup controlId="email" bsSize="large">
          <ControlLabel>Email</ControlLabel>
          <FormControl
            autoFocus
            type="email"
            value={email}
            onChange={e => setEmail(e.target.value)}
          />
        </FormGroup>
        <FormGroup controlId="password" bsSize="large">
          <ControlLabel>Password</ControlLabel>
          <FormControl
            value={password}
            onChange={e => setPassword(e.target.value)}
            type="password"
          />
        </FormGroup>
        <FormGroup controlId="confirmPassword" bsSize="large">
          <ControlLabel>Confirm Password</ControlLabel>
          <FormControl
            value={confirmPassword}
            onChange={e => setConfirmPassword(e.target.value)}
            type="password"
          />
        </FormGroup>
        <LoaderButton
          block
          bsSize="large"
          disabled={!validateForm()}
          type="submit"
          isLoading={isLoading}
          text="Signup"
          loadingText="Signing up…"
        />
      </form>
    );
  };

  return (
    <div id="signup-container" className="Signup">
      {newUser === null
        ? renderForm()
        : renderConfirmationForm()}
    </div>
  );
};

export default Signup;
