import React from "react";
import "./Home.css";

const Home = props => {
    return (
      <div className="Home">
        <div className="lander">
          <h1><span className="logo-gld">SE</span>Rec<span className="logo-gld">o</span>n</h1>
          <p>An <span className="logo-gld">Intelligence</span> Gathering App</p>
        </div>
      </div>
    );
};

export default Home;
