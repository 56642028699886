import React from "react";
import { Chart } from "react-google-charts";
import './ChartTests.css';
let serpItems = require('./chart');


export default (props) => {

  // order by search term / location, then by time
  serpItems = serpItems.sort(compareValues('search_link', 'asc'));
  serpItems = serpItems.sort(compareValues('search_end_time', 'asc'));

  let serpArray = Object.entries(serpItems);
  let chartData = [['Date', 'Page.Pos']];

  serpArray.forEach((item) => {

    let { search_end_time, search_results} = item[1];
    let finalDate = formatDate(search_end_time);
    let pageArray = Object.entries(search_results);

    pageArray.forEach(page => {

      let [ pgNum, ] = page;
      let posArray = Object.entries(page[1]);

      // extract "pgNum.pos" chart data we need
      posArray.forEach(pos => {
        if(findURL(pos[1])) {
          pgNum = pgNum.split('_');
          let cleanPos = pos[0].split('_');
          let regex = /^0/g;
          pgNum[1] = (pgNum[1] < 10 ? pgNum[1].replace(regex, '') : pgNum);
          cleanPos[1] = (cleanPos[1] < 10 ? cleanPos[1].replace(regex, '') : cleanPos[1]);
          chartData.push([finalDate, parseFloat(`${pgNum[1]}.${cleanPos[1]}`)]);
        }
      })
    })
  });

  return (

    <Chart
      width={'100%'}
      height={'400px'}
      chartType="LineChart"
      className="line-chart"
      loader={<div>Loading Chart...</div>}
      data={chartData}
      options={{
        title: 'Yelp Search: "sandblasting Diamond Springs, CA"',
        legend: "none",
        hAxis: {
          title: 'BestSandblasting.com Search Results Positioning Over Time',
        },
        vAxis: {
          title: 'Page Position',
          baseline: 4,
          direction: -1,
          format: 'short',
          gridlines: {
            color: '#333',
            count: 4,
          },
          minorGridlines: {
            color: 'red',
            count: 0,
          },
          textPosition: 'out',
        },
        //curveType: 'function', // default none
      }}
      rootProps={{ 'data-testid': '1' }}
    />
  );

  // sort object values by key
  function compareValues(key, order='asc') {
    return function(a, b) {
      if(!a.hasOwnProperty(key) ||
        !b.hasOwnProperty(key)) {
        return 0;
      }
      const varA = (typeof a[key] === 'string') ?
        a[key].toUpperCase() : a[key];
      const varB = (typeof b[key] === 'string') ?
        b[key].toUpperCase() : b[key];
      let comparison = 0;
      if (varA > varB) {
        comparison = 1;
      } else if (varA < varB) {
        comparison = -1;
      }
      return (
        (order === 'desc') ?
          (comparison * -1) : comparison
      );
    };
  }

  // highlight customer url when found in results
  function findURL(resultItem) {
    try {
      if (resultItem) {
        if (resultItem.toLowerCase().includes('best-sandblasting-service-nevada-city-11')) {
          return true;
        } else {
          return false;
        }
      } else {
        // item is null or undefined, do nothing for now
      }
    } catch (err) {
      console.error(err, err.stack);
    }
  }

  function formatDate(timestamp) {
    let formatted = new Date(parseInt(timestamp));
    let day = formatted.getDate();
    let month = formatted.getMonth() + 1;
    let year = formatted.getFullYear();
    return(`${month}.${day}.${year}`);
  }
}


