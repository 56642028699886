import React, { useContext } from "react";
import "./Settings.css";
import AuthContext from '../auth-context';

export default (props) => {
  const auth = useContext(AuthContext);

  if (!auth.authenticated) {
    props.history.push('/login');
    return null;
  } else {
    return (
      <div className="Settings">
        <h3>Data Acquisition and Report Settings</h3>
      </div>
    );
  }
};

