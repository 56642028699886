import React, { useContext, useEffect, useState } from "react";
import "./Google.css";
import AuthContext from '../auth-context';
import { API } from "aws-amplify";
import SearchResult from '../components/GoogleSearchResult';
import { openDB } from 'idb';
import {ControlLabel, Form, FormControl, FormGroup, Radio } from "react-bootstrap";
import SearchChart from '../components/GoogleSearchChart';
import Spinner from '../components/Spinner';

export default (props) => {

  const auth = useContext(AuthContext);

  const [serpItems, setSerpItems] = useState([]);
  const [termSelectState, setTermSelectState] = useState(null);
  const [term, setTerm] = useState('');
  const [location, setLocation] = useState('');
  const [locationSelectState, setLocationSelectState] = useState(null);
  const [displayType, setDisplayType] = useState('details');
  const [custRec, setCustRec] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [initializing, setInitializing] = useState(true);

  const dbPromise = createIndexedDB();

  useEffect( () => {
    if (auth.authenticated === true) {
      if (termSelectState === 'success' && locationSelectState === 'success') {
        getResults();
      }
    }
  }, [term, location]);

  useEffect(() => {
    if (auth.authenticated === true) {
      getCustomerRecord();
      let lastTerm = localStorage.getItem('term');
      let lastLocation = localStorage.getItem('location');
      let lastDisplay = localStorage.getItem('displayType');
      if(lastTerm && lastLocation) {
        setTermSelectState('success');
        setLocationSelectState('success');
        setTerm(lastTerm);
        setLocation(lastLocation);
      } else {
        setTermSelectState('error');
        setLocationSelectState('error');
      }
      if(lastDisplay) {
        setDisplayType(lastDisplay);
      }
    }
  },[setCustRec]);

  const handleTermChange = (value) => {
    if (value !== 'select') {
      setTermSelectState('success');
      setTerm(value);
      localStorage.setItem('term', `${value}`);
    } else {
      setTermSelectState('error');
    }
  };

  const handleLocationChange = (value) => {
    if (value !== 'select') {
      setLocationSelectState('success');
      setLocation(value);
      localStorage.setItem('location', `${value}`);
    } else {
      setLocationSelectState('error');
    }
  };


  const getCustomerRecord = async () => {
    let response;
    try {
      let localResponse = await getLocalCustomerRecord();
      if (localResponse.length) {
        console.log('displaying locally cached customer data');
        setCustRec(localResponse);
        console.log('now getting customer data from network and storing locally');
        response = await API.get("dev-serp-api-v5", '/customer');
        if(response.length) {
          setCustRec(response);
          saveCustomerRecordLocally(response);
        }
      } else {
        console.log('getting customer data from network and storing locally');
        response = await API.get("dev-serp-api-v5", '/customer');
        if(response.length) {
          setCustRec(response);
          saveCustomerRecordLocally(response);
        }
      }
    } catch(e) {
      console.log('ERROR: ' + e);
    }
  };


  /*
   indexDB first, then network
   network calls are using amplify to securely access an aws cognito/iam secured
    - api gateway which is serving a lambda function which then accesses dynamodb
  */
  const getResults = async () => {
    let response;
    try {
      let localResponse = await getSomeLocalItems();
      if (localResponse.length) {
        console.log('displaying locally cached item data');
        setSerpItems(localResponse);
        response = await API.get("dev-serp-api-v5", `/google?term=${term}&location=${location}`);
        console.log('now getting new data from network and updating local cache');
        if(response.length) {
          setSerpItems(response);
          saveItemDataLocally(response);
        }
      } else {
        console.log('getting data from network and storing locally');
        setIsLoading(true);
        response = await API.get("dev-serp-api-v5", `/google?term=${term}&location=${location}`);
        if(response.length) {
          setSerpItems(response);
          saveItemDataLocally(response);
        } else {
          // this allows reportData to clear from previous render, and display noDataMessage, but only after network data attempt, vs initially
          setInitializing(false);
          setSerpItems([]);
        }
      }
    } catch(e) {
      console.log(`API ERROR: ${e}`);
    }
    setIsLoading(false);
  };

  if (!auth.authenticated) {
    props.history.push('/login');
    return null;

  } else {

    if (custRec !== null) {

      // deconstruct our needed data
      var [{customer_name, customer_url, search_config: { terms, locations}}] = custRec;

      let lastTerm = localStorage.getItem('term');
      let lastLocation = localStorage.getItem('location');

      var selectTerms = [];
      if (term === lastTerm) {
        // do nothing...this keeps selected option in proper order, vs at top of list
      } else {
        selectTerms.push(<option key="0.5" value="select">Choose One</option>);
      }
      terms.map((term, index) => {
        if (term === lastTerm) {
          selectTerms.push(<option key={index} defaultValue={term} value={term}>{term}</option>);
        } else {
          selectTerms.push(<option key={index} value={term}>{term}</option>);
        }
        return selectTerms;
      });

      var selectLocations = [];
      if (location === lastLocation) {
        // do nothing...this keeps selected option in proper order, vs at top of list
      } else {
        selectLocations.push(<option key="0.5" value="select">Choose One</option>);
      }
      locations.map((location, index) => {
        if (location === lastLocation) {
          selectLocations.push(<option key={index} defaultValue={location} value={location}>{location}</option>);
        } else {
          selectLocations.push(<option key={index} value={location}>{location}</option>);
        }
        return selectLocations;
      });
    }

    return (
      <div className="Google">
        <div className="g-reportHeader">
          <h3 className="g-reportTitle">{(custRec != null ? customer_name : null)}<br />Google Position Report</h3>
          <div className="g-controls-container">
            <p className="g-controls-info">(Choose the report term and location below)</p>
            <Form inline id="g-controls-form">
              <FormGroup controlId="formControlsSelectTerm" validationState={termSelectState}>
                <ControlLabel>Select Term</ControlLabel>
                <FormControl bsSize="sm" componentClass="select" value={term} placeholder="select" onChange={e => handleTermChange(e.target.value)}>
                  {selectTerms}
                </FormControl>
              </FormGroup>

              <FormGroup controlId="formControlsSelectLocation" validationState={locationSelectState}>
                <ControlLabel>Select Location</ControlLabel>
                <FormControl bsSize="sm" componentClass="select" value={location} placeholder="select" onChange={e => handleLocationChange(e.target.value)}>
                  {selectLocations}
                </FormControl>
              </FormGroup>

              { isLoading ?
                <Spinner
                  isLoading={isLoading}
                  bssize="small"
                />
                : <div className="g-spinner-placeholder"> </div>
              }

              <FormGroup className="g-display-type" controlId="formControlsDisplayTypeRadio">
                <Radio
                  name="displayType"
                  title="Show Details"
                  value="details"
                  checked={(displayType === 'details')}
                  onChange={e => handleDisplayRadioChange(e.target.value)}
                  inline>
                  Show Details
                </Radio>{' '}
                <Radio
                  name="displayType"
                  title="Show Chart"
                  value="chart"
                  checked={(displayType === 'chart')}
                  onChange={e => handleDisplayRadioChange(e.target.value)}
                  inline>
                  Show Chart
                </Radio>{' '}
              </FormGroup>
            </Form>
          </div>
        </div>
        <div id="reportData">
        {
          // if details radio is selected show details view
          (displayType === 'details' && serpItems.length
            ? <SearchResult
              serpItems={serpItems}
              engine='Google'
              customer_url={customer_url}
            />
            : null)
        }

        {
          // if chart radio is selected show chart view
          (displayType === 'chart' && serpItems.length
            ? <SearchChart
              serpItems={serpItems}
              engine='Google'
              customer_name={customer_name}
              customer_url={customer_url}
              term={term}
              location={location}
            />
            : null)
        }

        {
          // if no data after async call(s), display message instead of results
          (!serpItems.length && initializing === false ?
            <div className="g-noDataMessage">
              There were no results available for this search report.
              Please check your report settings to insure a data acquisition schedule exists.
            </div>
            : null )
        }
        </div>
      </div>
    )
  }

  /////////// functions ///////////

  function createIndexedDB() {
    if (!('indexedDB' in window)) {return null;}
    return openDB('google-serp', 1, {
      upgrade(db, oldVersion, newVersion, transaction) {
        if (!db.objectStoreNames.contains('items')) {
          var itemsOS = db.createObjectStore('items', {keyPath: 'search_id'});
          itemsOS.createIndex('search_link', 'search_link');
        }
      },
    });
  }

  function saveItemDataLocally(items) {
    if (!('indexedDB' in window)) {return null;}
    return dbPromise.then(db => {
      const tx = db.transaction('items', 'readwrite');
      const store = tx.objectStore('items');
      return Promise.all(items.map(item => store.put(item)))
        .catch(() => {
          tx.abort();
          throw Error('Items were not added to the store');
        });
    });
  }

  /*
  function getAllLocalItems() {
    if (!('indexedDB' in window)) {return null;}
    return dbPromise.then(db => {
      const tx = db.transaction('items', 'readonly');
      const store = tx.objectStore('items');
      return store.getAll();
    });
  }
  */

  function getSomeLocalItems() {

    if (!('indexedDB' in window)) {return null;}

    let regex = / /g;
    let termPlus = term.replace(regex, '+');
    let locationPlus = location.replace(regex, '+');

    return dbPromise.then(async db => {

      const tx = db.transaction('items', 'readonly');
      //const store = tx.objectStore('items');
      tx.objectStore('items');
      return await db.getAllFromIndex(
        'items',
        'search_link',
        //`https://www.yelp.com/search?cflt=%27${term}&find_loc=${location}%27`);
        `https://www.google.com/search?q=%27${termPlus}+${locationPlus}%27`)

      /* this works
      let cursor = await db.transaction('items').store.openCursor();
      while (cursor) {
        console.log(cursor.key, cursor.value);
        cursor = await cursor.continue();
      }
      */

      /* this works
      const value = await db.get('items', "2776f4b1-367d-11e9-b978-bbe8146f516e");
      console.log(value);
      */
    });

  }

  function getLocalCustomerRecord() {
    if (!('indexedDB' in window)) {
      return null;
    }

    return dbPromise.then(async db => {

      const tx = db.transaction('items', 'readonly');
      tx.objectStore('items');
      return await db.getAll(
        'items',
        'customer_record'
      );
      //return await db.get('items', 'customer_record')
    });
  }

  function saveCustomerRecordLocally(items) {
    if (!('indexedDB' in window)) {return null;}
    return dbPromise.then(db => {
      const tx = db.transaction('items', 'readwrite');
      const store = tx.objectStore('items');
      return Promise.all(items.map(item => store.put(item)))
        .catch(() => {
          tx.abort();
          throw Error('Customer record was not added to the store');
        });
    });
  }

  function handleDisplayRadioChange(value) {
    setDisplayType(value);
    localStorage.setItem('displayType', `${value}`);
  }

}
