import React from "react";
import { Chart } from "react-google-charts";
import './YelpSearchChart.css';


export default (props) => {

  let engine = props.engine;
  let serpItems = props.serpItems;
  let term = props.term;
  let location = props.location;
  let customer_name = props.customer_name;
  let customer_yelp_url = props.customer_yelp_url;

  if (serpItems.length > 0) {

    // order by search term / location, then by time
    serpItems = serpItems.sort(compareValues('search_link', 'asc'));
    serpItems = serpItems.sort(compareValues('search_end_time', 'asc'));

    let serpArray = Object.entries(serpItems);
    let chartData = [['Date', 'Page.Pos']];

    serpArray.forEach((item) => {

      let { search_end_time, search_results} = item[1];
      let reportDate = formatDate(search_end_time);
      let pageArray = Object.entries(search_results);
      let datesArray = [];

      pageArray.forEach(page => {

        let [ pgNum, ] = page;
        let posArray = Object.entries(page[1]);

        // extract "pgNum.pos" chart data we need
        posArray.forEach(pos => {
          if(findURL(pos[1])) { // chart if our URL
            // split strings on underscore to get the pieces we need
            pgNum = pgNum.split('_');
            let cleanPos = pos[0].split('_');
            // if page and position numbers are less than 10, remove the leading zero
            let regex = /^0/g;
            let pgNumFinal = (pgNum[1] < 10 ? pgNum[1].replace(regex, '') : pgNum[1]);
            let cleanPosFinal= (cleanPos[1] < 10 ? cleanPos[1].replace(regex, '') : cleanPos[1]);
            // TODO: this is a hack to give google charts the numeric value it needs -- CONTINUE RESEARCH
            // problem is -- page.pos of 1.10 rounds to 1.1, or 1.11, or 1.12, etc...which then charts as position 1 vs position 10,11,12
            //  -- temp solution: if position number is 10 or greater, we set position to 999, which then charts just below 10 aka 'next page'
            //  -- this represents a closer visual reality until a resolution is discovered.
            //  ALSO NOTE: this condition only applies when we're highlighting our customer url on google charts with
            //  -- a charted position of 10 or greater...
            if (cleanPosFinal >= 10) {
              cleanPosFinal = 999;
            }
            // add data array as a chart "row" to the chartData array
            chartData.push([reportDate, parseFloat(`${pgNumFinal}.${cleanPosFinal}`)]);
            datesArray.push(reportDate);
          }
        })
      });
      // record date if not already listed via findURL, and even through no positional showing for client we need the perspective
      if(datesArray.indexOf(reportDate) === -1) {
        chartData.push([reportDate, 3.999]);
      }
    });


    return (

      <Chart
        width={'100%'}
        height={'400px'}
        chartType="LineChart"
        className="y-line-chart"
        loader={<div>Loading Chart...</div>}
        data={chartData}
        options={{
          title: `${engine} Search: "${term} ${location}"`,
          legend: {
            position: 'bottom',
          },
          lineWidth: 4,
          hAxis: {
            title: `${customer_name} Search Results Positioning Over Time`,
          },
          vAxis: {
            title: 'Page Position',
            minValue: 1,
            maxValue: 4,
            baseline: 4,
            direction: -1,
            format: 'short',
            gridlines: {
              color: '#333',
              count: 4,
            },
            textPosition: 'out',
          },
          //curveType: 'function', // default none
        }}
        rootProps={{ 'data-testid': '1' }}
      />
    );
  }

  // sort object values by key
  function compareValues(key, order='asc') {
    return function(a, b) {
      if(!a.hasOwnProperty(key) ||
        !b.hasOwnProperty(key)) {
        return 0;
      }
      const varA = (typeof a[key] === 'string') ?
        a[key].toUpperCase() : a[key];
      const varB = (typeof b[key] === 'string') ?
        b[key].toUpperCase() : b[key];
      let comparison = 0;
      if (varA > varB) {
        comparison = 1;
      } else if (varA < varB) {
        comparison = -1;
      }
      return (
        (order === 'desc') ?
          (comparison * -1) : comparison
      );
    };
  }

  // highlight customer url when found in results
  function findURL(resultItem) {
    try {
      if (resultItem) {
        if (resultItem.toLowerCase().includes(`${customer_yelp_url}`)) {
          return true;
        } else {
          return false;
        }
      } else {
        // item is null or undefined, do nothing for now
      }
    } catch (err) {
      console.error(err, err.stack);
    }
  }

  function formatDate(timestamp) {
    let date = new Date(parseInt(timestamp));
    let month = date.toLocaleString('en-us', { month: 'short' });
    let day = date.getDate();
    let year = date.getFullYear();
    return(`${month} ${day} ${year}`);
  }

  return <div className="y-chart-message"></div>;
}




